import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox
} from 'react-bootstrap';
import axios from 'axios';
import config from '../config';
import SpinnerButton from './SpinnerButton';
import DismissableAlert from './DismissableAlert';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

import DateTime from 'react-datetime';
import Moment from 'moment';

import 'react-datetime/css/react-datetime.css';

import './EditPlayerForm.css';

class EditPlayerForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saved: false,
            isLoading: false,
            refreshIntervalInput: 0,
            player: {
                name: '',
                location: '',
                macAddress: '',
                organisationUUID: '',
                timezone: 'Europe/London',
                installDate: new Moment(),
                serialNumber: '',
                refreshInterval: 900000
            },
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            }
        };
    }

    handleDateChange = date => {
        const player = this.state.player;

        player.installDate = date;

        // update state
        this.setState({
            player
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.player !== this.state.player) {
            if (nextProps.player) {
                this.setState({ player: nextProps.player, refreshIntervalInput: ((nextProps.player.refreshInterval / 60000).toFixed(2))  });
            }
        }
    }

    componentDidMount() {}

    validateForm() {
        return (
            this.state.player.name.length > 0 &&
            this.state.player.macAddress.length > 0 &&
            this.state.player.organisationUUID.length > 5
        );
    }

    handleChange = event => {
        const player = this.state.player;
        player[event.target.id] = event.target.value;

        // update state
        this.setState({
            player
        });
    };

    handleTimeZoneChange = timezone => {
        const player = this.state.player;
        player.timezone = timezone;

        // update state
        this.setState({
            player
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        event.preventDefault();

        this.setState({ isLoading: true });

        const uuid = this.state.player.uuid;

        // TODO: Validate that it's a valid UUID.

        try {
            var self = this;
            axios
                .put(`${config.baseURL}/players/${uuid}`, {
                    name: this.state.player.name,
                    location: this.state.player.location,
                    macAddress: this.state.player.macAddress,
                    organisationUUID: this.state.player.organisationUUID,
                    timezone: this.state.player.timezone,
                    installDate: this.state.player.installDate,
                    serialNumber: this.state.player.serialNumber,
                    refreshInterval: this.state.player.refreshInterval
                })
                .then(function(response) {
                    self.setState({
                        saved: true,
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditPlayerForm.success')}:`,
                            style: 'success',
                            message: `${t('EditPlayerForm.saved_message')}`
                        }
                    });
                })
                .catch(function(err) {
                    self.setState({
                        isLoading: false,
                        error: {
                            hidden: false,
                            title: `${t('EditPlayerForm.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: {
                    hidden: false,
                    title: `${t('EditPlayerForm.error')}:`,
                    style: 'danger',
                    message: `${t('EditPlayerForm.unknown_error')}`
                }
            });
        }
    };

    handleRefreshIntervalChange = (e) => {
        const value = e.target.value;

        this.setState({ refreshIntervalInput: value });

        const minutes = parseFloat(value);
        if (!isNaN(minutes)) {
            this.setState({ refreshInterval: minutes * 60000 });
            const player = this.state.player;

            player.refreshInterval = minutes * 60000;

            this.setState({
                player
            });
    
        }
    };

    handleBlur = () => {
        const minutes = parseFloat(this.state.refreshIntervalInput);
        if (!isNaN(minutes)) {
            this.setState({ refreshIntervalInput: minutes.toFixed(2) });
        } else {
            this.setState({ refreshIntervalInput: (this.state.refreshInterval / 60000).toFixed(2) });
        }
    };

    renderForm() {
        const { t } = this.props;

        return (
            <div id="EditPlayerForm">
                <form onSubmit={this.handleSubmit}>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup controlId="name" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPlayerForm.name')}
                                </ControlLabel>
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.player.name}
                                    onChange={this.handleChange}
                                />
                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="location" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPlayerForm.location')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-location-arrow" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.player.location}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="macAddress" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPlayerForm.mac_address')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-microchip" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.player.macAddress}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="installDate" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPlayerForm.install_date')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="far fa-calendar-alt" />
                                    </span>
                                    <DateTime
                                        dateFormat="MMMM Do YYYY"
                                        timeFormat={false}
                                        utc={true}
                                        onChange={this.handleDateChange}
                                        value={
                                            new Moment(
                                                this.state.player.installDate
                                            )
                                        }
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="serialNumber" bsSize="sm">
                                <ControlLabel>
                                    {t('EditPlayerForm.serial_number')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-qrcode" />
                                    </span>
                                    <FormControl
                                        type="text"
                                        value={this.state.player.serialNumber}
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row bsClass="form-row">
                        <Col sm={6}>
                            <FormGroup controlId="timezone" bsSize="sm">
                                <label>{t('EditPlayerForm.timezone')}</label>
                                <TimezonePicker
                                    absolute={true}
                                    overflow={'none'}
                                    style={{ width: '100%' }}
                                    defaultValue="Europe/London"
                                    placeholder={t(
                                        'EditPlayerForm.choose_timezone'
                                    )}
                                    value={this.state.player.timezone}
                                    onChange={this.handleTimeZoneChange}
                                />

                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.optional')}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup controlId="serialNumber" bsSize="sm">
                                <ControlLabel>
                                {t('EditPlayerForm.refresh_interval')}
                                </ControlLabel>
                                <InputGroup>
                                    <span className="input-group-addon">
                                        <i className="fa fa-clock" />
                                    </span>
                                     <FormControl
                                        type="text"
                                        value={this.state.refreshIntervalInput}
                                        onChange={this.handleRefreshIntervalChange}
                                        onBlur={this.handleBlur} // Format input when user leaves the field
                                    />
                                </InputGroup>
                                <small className="form-text text-muted">
                                    {t('EditPlayerForm.required')}
                                </small>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <FormGroup bsSize="sm">
                                <SpinnerButton
                                    block
                                    bsStyle="primary"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text={`${t('EditPlayerForm.save_player')}`}
                                    loadingText={`${t(
                                        'EditPlayerForm.saving_player'
                                    )}`}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }

    renderSuccessMessage() {
        return (
            <div id="successMessage">
                <form>
                    <Row bsClass="form-row">
                        <Col sm={12}>
                            <DismissableAlert
                                title={this.state.error.title}
                                message={this.state.error.message}
                                hidden={this.state.error.hidden}
                                style={this.state.error.style}
                            />
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
    //                {this.state.saved ? this.renderForm() : this.renderSuccessMessage()}

    render() {
        return <div className="EditPlayerForm">{this.renderForm()}</div>;
    }
}

export default translate('translations')(EditPlayerForm);
